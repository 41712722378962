/*
  -- NAVIGATORE --
  Interfaccia semplificata per spostarsi fra le pagine.
*/
import { push, pop, replace, location } from "svelte-spa-router"
location.su_cambio = location.subscribe

window.navigatore = {
  rotta_corrente: location,
  verso: function(rotta) {
    return push(rotta)
  },
  indietro: function() {
    return pop()
  },
  cambia_rotta: function(rotta) {
    return replace(rotta)
  },
  parametri: () => getParamsFromUri()
}

/**
 * Get params from query uri + check if there is any boolean and return with his type.
 *
 * @returns {{[p: string]: string}|{}}
 */
function getParamsFromUri () {
  const url = window.location.href.includes("#")
      ? new URL("https://placeholder.com/?" + window.location.href.split("?")[1])
      : new URL(window.location.href);

  return parseToBoolean(Object.fromEntries(url.searchParams.entries())) || {};
}


/**
 * Recursively parse string booleans inside objects and arrays.
 *
 * @param {any} params - The object or value to process.
 * @returns {any} - The transformed object with boolean values.
 */
function parseToBoolean(params) {
  if (typeof params === 'string') {
    if (params === 'true') return true;
    if (params === 'false') return false;
    return params; // Return as is if not a boolean string
  }

  if (Array.isArray(params)) {
    return params.map(parseToBoolean); // Recursively process array elements
  }

  if (typeof params === 'object' && params !== null) {
    return Object.fromEntries(
        Object.entries(params).map(([key, value]) => [key, parseToBoolean(value)])
    );
  }

  return params; // Return the value if it's neither string, array, nor object
}

